
import { Component, Vue, Prop } from 'vue-property-decorator'
import { HistoryProgressInterface } from '@/store/types/History/History'
import ProgressBar from '@/components/ProgressBar/Index.vue'

@Component({
  components: {
    ProgressBar,
  },
})
export default class ItemHistoryComponent extends Vue {
  @Prop({ required: true }) readonly element!: HistoryProgressInterface
  @Prop({ required: false, default: 'client' }) readonly type!: string

  private role = this.$store.state.AuthModule.AuthState.role
  public showMore = true
  private businessFrameworks = this.$store.state.PolimorficModule.PolimorficState.frameworks.data

  private goToSummaryCompliance() {
    this.$store.commit('GapModule/SET_COMPLETED_INFO', this.element.completed)
    this.$store.commit('GapModule/SET_PENDING_INFO', this.element.pending)
    this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', {
      name: this.element.framework,
      id: this.$store.state.PolimorficModule.PolimorficState.framework_selected['id'],
    })
    this.$router.push('/app/compliance')
    this.$mixpanel.track('Boton Ver resumen de cumplimiento - Seccion Historial')
  }

  private goToDashboard() {
    const ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || []
    let frameworkId
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', this.element.version)
    this.$store.commit('GapModule/SET_DATE_VERSION', this.element.created_at)
    this.$store.commit('GapModule/SET_COMPLETED_INFO', this.element.completed)
    this.$store.commit('GapModule/SET_PENDING_INFO', this.element.pending)
    this.$store.commit('GapModule/SET_FRAMEWOK_HISTORY', this.element.framework)

    this.businessFrameworks.forEach((element: any) => {
      if (element.name === this.element.framework) {
        frameworkId = element.id
        this.$store.commit('GapModule/SET_FRAMEWOK_HISTORY_ID', element.id)
      }
    })

    if (!ADMIN_ROLES.some((role: string) => this.role.includes(role))) {
      this.$router.push({ name: 'startupDashboardGap' })
    } else {
      this.$router.push({ name: 'DashboardGap', params: { id: `${frameworkId}` } })
    }
    this.$mixpanel.track('Boton Ver estado completo')
  }

  private goDetailControl(element: HistoryProgressInterface) {
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', this.element.version)
    this.$store.commit('GapModule/SET_DETAIL_SELECTED', element)
    this.$store.commit('HistoryModule/SET_HISTORY_NAV', this.element.changed_controls)
    this.$store.commit('GapModule/SET_DATE_VERSION', this.element.created_at)

    this.$router.push(`/startup/project/${element.id}/control-detail?history=true`)
    this.$mixpanel.track('Control - Seccion Historial', { Control: this.element })
  }

  get getNameAction() {
    return this.element.open ? 'Mostrar menos' : 'Mostrar más'
  }

  get getIconAction() {
    return this.element.open ? 'expand_less' : 'expand_more'
  }
}
